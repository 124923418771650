import React from 'react';
import './loader.style.css';

function LoadingBar() {
  return (
    <div className="loading-bar-container">
      <div className="loader"></div>
    </div>
  );
}

export default LoadingBar;
