import React from "react";
import GM2Logo from "assets/gm-logo.png";
import menu from "assets/menu.png";

const Navbar = () => {
  return (
    <div>
      <div className="bg-white/75 shadow-sm">
        <div className="flex justify-between items-center p-6 md:py-6 md:px-20">
          <div>
            <img src={GM2Logo} alt="GM2 Logo" className="w-28" />
          </div>
          <div className="cursor-pointer">
            <img src={menu} alt="menu" className="w-6" />
          </div>
        </div>
      </div>

      {/* Ajouter une image en haut au centre */}
      <div className="flex justify-center items-center">
        <img src="https://gm2tours.ma/src/head.jpg" className="w-100 m-0"/>
      </div>
    </div>
  );
};

export default Navbar;
