import React, { useContext, useEffect, useState } from "react";
import seatIcon from "assets/icons/seat-icon.png";
import carIcon from "assets/icons/car-icon.png";
import { AppContext } from "context/AppContext";
import { Link } from "react-router-dom";
import { dateFormatter } from "utils/dateFormatter";

const VoyageDirection = ({
  passengerLimit,
  direction,
  vehicle:newV,
  hasSearch = false,
  hasShadow = false,
  date,
}) => {
  //const { contextCities } = useContext(AppContext);
  const [dateToShow, setDateToShow] = useState('')
  const vehicle = newV?.vehicleData
  const shadowStyle = hasShadow && "shadow-md";
  const absoluteBtnStyle =
    "absolute bg-red bottom-[-15px] left-1/2 -translate-x-1/2";
  const normalBtnStyle = "block text-center";

  // const findCityById = (id) => {
  //   return contextCities.find((city) => city.id === id);
  // };
  // const findCityChildren = (parentId, childId) => {
  //   return contextCities
  //     .find((city) => city.id === parentId)
  //     .children.find((child) => child._id === childId);
  // };

  useEffect(() => {
    if(date.date){
      setDateToShow( dateFormatter(
        `${date.date}: ${date.hour}`,
        "DD/MM/YYYY HH:mm"
      ))
    }else {
     setDateToShow(dateFormatter(date, "DD/MM/YYYY HH:mm"))
    }
  }, [])
  
  
const arrival= newV?.arrivalLocation?.label
const departure = newV?.departureLocation?.label


  return (
    <div
      className={`flex-shrink relative h-fit ${shadowStyle} rounded-xl p-6 bg-white`}
    >
      {/* Direction*/}
      <div >
        <ol className="relative border-l border-dashed border-black ">
          <li className="mb-10 ml-6">
            <span className="flex absolute -left-[9px] justify-center items-center w-4 h-4 bg-primary rounded-full"></span>
            <h3 className="flex items-center text-lg font-semibold text-gray-900 ">
              {departure}
              {/* {vehicle.availability[0].from.location} */}
            </h3>
            <p className="mb-4 text-base font-normal text-gray-500">
              {/* {dateFormatter(
                `${date.date}: ${date.hour}`,
                "DD/MM/YYYY HH:mm"
              )} */}
              {dateToShow}
            </p>
          </li>
          <li className="mb-10 ml-6">
            <span className="flex absolute bottom-0 -left-[9px] justify-center items-center w-4 h-4 bg-primary rounded-full"></span>
            <h3 className="flex items-center text-lg font-semibold text-gray-900 ">
              {/* {findCityById(direction.to.location)?.label} */}
              {/* {
                findCityChildren(
                  vehicle.availability[0].from.location,
                  vehicle.availability[0].to.location
                )?.label
              } */}
              {arrival}
            </h3>
          </li>
        </ol>
      </div>
      {/* Seats */}
      <div className="flex items-center gap-5 mt-10">
        <img className="w-8" src={seatIcon} alt="" />
        <p>
          Passagers: <b>{vehicle?.passengers}</b>
        </p>
      </div>
      {/* Vehicul */}
      <div className="flex items-center gap-5 mt-2">
        <img className="w-8" src={carIcon} alt="" />
        <p>
          <b>{vehicle?.name}</b>
        </p>
      </div>
      {/* Plus */}
      <div className="flex gap-2 justify-start my-5">
        {vehicle?.amenities &&
          vehicle?.amenities.map((amt) => (
            <img className="w-8" src={amt.icon} alt={amt.label} key={amt._id} />
          ))}
      </div>
      {hasSearch ? (
        <Link
          state={{ vehicle, passengerLimit, direction,newV,date }}
          to={`/voyage/${vehicle.id}`}
          className={`text-white text-lg font-bold bg-primary px-6 py-1 rounded-full ${
            hasSearch ? normalBtnStyle : absoluteBtnStyle
          }`}
        >
          {newV.price} DH
        </Link>
      ) : (
        <p
          className={`text-white text-lg font-bold bg-primary px-6 py-1 rounded-full ${
            hasSearch ? normalBtnStyle : absoluteBtnStyle
          }`}
        >
          {newV.price} DH
        </p>
      )}
    </div>
  );
};

export default VoyageDirection;
