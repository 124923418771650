import { AppContext } from "context/AppContext";
import { useState } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import NotFoundPage from "views/404";
import Home from "views/home";
import Voyage from "views/voyage";
import Layout from "./layout";

function App() {
  const [cities, setCities] = useState([]);
  return (
    <AppContext.Provider
      value={{ contextCities: cities, setContextCities: setCities }}
    >
      <Layout>
        <BrowserRouter>
          <Routes >
            <Route path="*" element={<NotFoundPage />} scrollToTop={true} />
            <Route path="/" element={<Home />} scrollToTop={true}/>
            <Route path="/voyage/:id" element={<Voyage />} scrollToTop={true} />
          </Routes>
        </BrowserRouter>
      </Layout>
    </AppContext.Provider>
  );
}

export default App;
