import { apiClient } from "config";

// get all locations from API
const getLocations = async () => {
  return await apiClient.get("/locations");
};

const getLocationsCars = async (data) => {
  return await apiClient.post("/trips", data);
};

export { getLocations, getLocationsCars };
