import React from "react";
import step1 from "assets/step-1.png";
import step2 from "assets/step-2.png";
import step3 from "assets/step-3.png";
import topShape from "assets/top-shape.png";
import bottomShape from "assets/bottom-shape.png";

const StepsCards = () => {
  return (
    <div className="relative bg-gray-100">
      <img src={topShape} className="absolute w-full top-0" alt="" />
      <img src={bottomShape} className="absolute w-full bottom-0" alt="" />
      <h1 className="text-3xl font-black text-center pt-10">
        Comment ça marche
      </h1>
      <div className="h-[1200px] flex items-center px-10 md:h-[600px]">
        <div className="flex flex-wrap gap-10 justify-around mx-auto md:max-w-5xl md:flex-nowrap">
          <div className="flex flex-col md:w-1/3">
            <img
              src={step1}
              alt="gm2 recherche service"
              className="w-[200px] mx-auto"
            />
            <h1 className="text-2xl font-semibold text-center text-primary my-5">
              Recherche
            </h1>
            <p className="text-black text-center">
              Cherchez le véhicule qui vous convient & à l'heure qui vous convient.
            </p>
          </div>

          <div className="flex flex-col md:w-1/3">
            <img
              src={step2}
              alt="gm2 recherche service"
              className="w-[230px] mx-auto"
            />
            <h1 className="text-2xl font-semibold text-center text-primary my-5">
              Validation
            </h1>
            <p className="text-black text-center">
              Choisissez le véhicule qui vous convient.
            </p>
          </div>

          <div className="flex flex-col md:w-1/3">
            <img
              src={step3}
              alt="gm2 recherche service"
              className="w-[200px] mx-auto"
            />
            <h1 className="text-2xl font-semibold text-center text-primary my-5">
              Paiement
            </h1>
            <p className="text-black text-center">
              Payez en toute sécurité votre véhicule avec chauffeur, afin de
              valider votre réservation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsCards;
