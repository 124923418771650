import React from "react";

const NotFoundPage = () => {
  return (
    <div className="h-[calc(100vh-200px)] flex justify-center items-center">
      <p>⛔ Oups page not found </p>
    </div>
  );
};

export default NotFoundPage;
