import Hero from "components/Hero";
import StepsCards from "components/StepsCards";
import React from "react";

const Home = () => {
  return (
    <>
      <Hero />
      <StepsCards />
    </>
  );
};

export default Home;
