import React from "react";

const PassagerForm = ({ number, validatePhone, passengers, setPassengers }) => {
  const handlePassenger = (e) => {
    // if (e.target.name === "phone_number") {
    //   // const limit = 4;
    //   validatePhone(e.target.value);
    // }
    setPassengers({
      ...passengers,
      [`passenger${number}`]: {
        ...passengers?.[`passenger${number}`],
        [e.target.name]: e.target.value,
      },
    });
  };
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div>
        <label className="mt-4 block">Adresse Email</label>
        <input
          pattern="[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
          onChange={(e) => handlePassenger(e)}
          type="email"
          name={`email`}
          className="custom-input pl-2.5"
          placeholder=""
          required
        />
      </div>
      <div className="flex gap-5">
        <div className="w-full">
          <label className="mt-4 block">Prénom</label>
          <input
            onChange={(e) => handlePassenger(e)}
            type="text"
            name={`first_name`}
            className="custom-input pl-2.5"
            maxLength={20}
            placeholder=""
            required
          />
        </div>
        <div className="w-full">
          <label className="mt-4 block">Nom de famille</label>
          <input
            onChange={(e) => handlePassenger(e)}
            type="text"
            name={`last_name`}
            className="custom-input pl-2.5"
            maxLength={20}
            placeholder=""
            required
          />
        </div>
      </div>

      <div>
        <label className="mt-4 block">Numéro de téléphone</label>
        <input
          pattern="(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}"
          onChange={(e) => handlePassenger(e)}
          type="number"
          max={9999999999}
          name={`phone_number`}
          className="custom-input pl-2.5"
          placeholder=""
          required
        />
      </div>
    </form>
  );
};

export default PassagerForm;
