import { Combobox } from "@headlessui/react";
import React, { useContext, useEffect, useState } from "react";
import { getLocations } from "services/locations";
import { LocationIcon, UserIcon } from "./shared/icons";
import { AppContext } from "context/AppContext";

const SearchForm = ({
  handlePassengers,
  handleAvailability,
  vehiculAvailaility,
  handleTime,
  setVehicle,
  formErrors,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [query, setQuery] = useState("");
  const [childQuery, setChildQuery] = useState("");
  const [cityChild, setCityChild] = useState("");
  const { setContextCities } = useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    try {
      getLocations().then(({ data }) => {
        console.log('data', data)
        setCities(data.results);
        setContextCities(data.results);
      });
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  }, [setContextCities]);
  const filterCities =
    query === ""
      ? cities
      : cities.filter((city) => {
          return city.label.toLowerCase().includes(query.toLowerCase());
        });

  const cityChildren =
    cities && cities.find((city) => city.label === selectedCity);

  const filterCityChildren =
    childQuery === ""
      ? cityChildren?.children
      : cityChildren &&
        cityChildren.children.filter((city) => {
          return city.label.toLowerCase().includes(childQuery.toLowerCase());
        });

  if (loading) {
    return "loading...";
  }
  if (error) {
    return "No data found";
  }
  return (
    <div className="flex flex-col gap-10 md:flex-row">
      {/* grp-1 */}
      <div className="flex flex-col gap-10 md:w-1/2">
        <div className="relative">
          De
          <div className="absolute top-[50%] left-0 flex items-center pl-3 pointer-events-none">
            <LocationIcon />
          </div>
          {/* <input
            className="custom-input"
            placeholder="Lieu de départ"
            onChange={(e) => {
              // setSelectedCity(e);
              handleAvailability({
                availability: {
                  ...vehiculAvailaility.availability,
                  from: {
                    location: e.target.value,
                  },
                },
              });
            }}
          /> */}
       
          <Combobox
            value={selectedCity}
            by="id"
            onChange={(e) => {
              setSelectedCity(e);
              handleAvailability({
                availability: {
                  ...vehiculAvailaility.availability,
                  from: {
                    location: cities.find((city) => city.label === e)?.id,
                  },
                },
              });
            }}
          >
             <Combobox.Button className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full p-2.5  w-full"}>
            <Combobox.Input
              placeholder="Lieu de départ"
              className="focus:invalid:border-2 bg-gray-50 pl-8 focus:invalid:border-red-500 outline-none block"
              pattern="[A-Za-z]+"
              onChange={(event) => {
                console.log('from')
                setQuery(event.target.value)
              }}
              autoComplete="off"
            />
          </Combobox.Button>
            <Combobox.Options className="absolute  max-h-[400px] overflow-scroll z-[999] bg-white w-full p-4 border border-gray-300 rounded-lg">
              {filterCities?.map((city) => (
                <Combobox.Option
                  key={city.id}
                  value={city.label}
                  className="cursor-pointer"
                >
                  {city.label} 
                </Combobox.Option>
              ))}
            </Combobox.Options>
         
          </Combobox>
          {/* {formErrors.from && (
            <label className="error-label">{formErrors.from}</label>
          )} */}
        </div>
        <div>
          <div className="relative">
            Vers
            <div className="absolute top-[50%] left-0 flex items-center pl-3 pointer-events-none">
              <LocationIcon />
            </div>
            {/* <input
              className="custom-input"
              placeholder="Lieu d'arrivée"
              onChange={(e) => {
                handleAvailability({
                  availability: {
                    ...vehiculAvailaility.availability,
                    to: {
                      location: e.target.value,
                    },
                  },
                });
              }}
            /> */}
            <Combobox
              value={cityChild}
              by="id"
              onChange={(e) => {
                setCityChild(e);
                handleAvailability({
                  availability: {
                    ...vehiculAvailaility.availability,
                    to: {
                      location: filterCityChildren.filter((city) => city.label === e)[0]?.id,
                     // _id: filterCityChildren.filter((city) => city.label === e)[0]?.id,
                    },
                  },
                });
              }}
            >
               <Combobox.Button className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full p-2.5  w-full"}>
              <Combobox.Input
                placeholder="Lieu d'arrivée"
                className="focus:invalid:border-2 bg-gray-50 pl-8 focus:invalid:border-red-500 outline-none block"
                pattern="[A-Za-z]+"
                onChange={(event) =>{
                  console.log('event.target.value', event.target.value)
                  setChildQuery(event.target.value)
                }}
                autoComplete="on"
              />
              </Combobox.Button>
              <Combobox.Options className="absolute  max-h-[400px] overflow-scroll z-[999] bg-white w-full p-4 border border-gray-300 rounded-lg">
                {filterCityChildren &&
                  filterCityChildren?.map((city) => (
                    <Combobox.Option
                      key={city.id}
                      value={city.label}
                      className="cursor-pointer"
                    >
                      {city.label}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            </Combobox>
            {/* {formErrors.to && (
              <label className="error-label">{formErrors.to}</label>
            )} */}
          </div>
        </div>
      </div>
      {/* grp-2 */}
      <div className="flex flex-col gap-10 md:w-1/2">
        <div className="flex gap-2">
          <div>
            Date
            <div className="relative">
              <input
                onChange={(e) => handleTime(e)}
                name="date"
                type="date"
                className="custom-input text-xs pl-2.5"
                placeholder="Date"
                required
              />
              {/* {formErrors.time && (
                <label className="error-label">{formErrors.time}</label>
              )} */}
            </div>
          </div>
          <div>
            Heure
            <div className="relative">
              <input
                onChange={(e) => handleTime(e)}
                name="hour"
                type="time"
                className="custom-input text-xs pl-2.5"
                placeholder="Heure"
                required
              />
              {/* {formErrors.time && (
                <label className="error-label">{formErrors.time}</label>
              )} */}
            </div>
          </div>
        </div>
        <div>
          Passagers
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <UserIcon />
            </div>
            <input
              onChange={(e) => handlePassengers(e.target.value)}
              type="number"
              defaultValue={1}
              min="1"
              max="99"
              pattern="[0-9]+"
              className="custom-input"
              placeholder="Passagers"
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
