import React from "react";
import GM2Logo from "assets/gm-logo.png";

const Footer = () => {
  return (
    <div className="p-10">
      <div className="flex flex-col gap-10 justify-around md:items-center md:flex-row">
        <div>
          <img src={GM2Logo} alt="gm2 logo" className="w-[200px]" />
        </div>
        <div className="border-l-4 border-primary">
          <div className="ml-5">
            <a href="/" className="link">
              contactez-nous
            </a>
            <a href="/" className="link">
              telephone: (+212) 0535 65 84 46
            </a>
            <a href="/" className="link">
              Fax: (+212) 0535 64 41 50
            </a>
            <a href="/" className="link">
              Email: info@gm2tours.ma
            </a>
            <a href="https://gm2tours.ma/cgu.php" className="link">
              Conditions générales de vente
            </a>
          </div>
        </div>
        <div>
          <p className="link">
            Adresse: <br />
            Bureau N°7, Avenue des FAR,
            <br />
            Immeuble Fes-Carrefour Fes, Maroc
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
