import SearchForm from "components/SearchForm";
import React, { useEffect, useRef, useState } from "react";
import barcode from "assets/barcode.png";
import VehiculCard from "components/VehiculCard";
import { getLocationsCars } from "services/locations";
import LoadingBar from "components/Loader/loader.component";
// import { getLocationsCars } from "services/locations";

const Hero = () => {
  const [time, setTime] = useState({ date: "", hour: "" });
  const [vehicle, setVehicle] = useState([]);
  const [passengerLimit, setPassengerLimit] = useState(1);
  // Form errors state
  const [hasError, setHasError] = useState({ error: false, message: null });
  // const [formErrors, setFormErrors] = useState({
  //   from: null,
  //   to: null,
  //   time: null,
  //   passengers: null,
  // });


  

  const vehiclesRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [vehiculAvailaility, setVehiculAvailaility] = useState({
    availability: {
      from: {
        location: "",
      },
      to: {
        location: "",
      },
      passengers: passengerLimit,
      time: time,
    },
  });


  useEffect(() => {
    console.log(time);
  }, [time])
  const submitForm = () => {
    const { availability } = vehiculAvailaility;
    console.log(time);
   
    if (
      availability.from.location.length <= 0 ||
      availability.to.location.length <= 0 ||
      time.date.length <= 0 ||
      time.hour.length <= 0
    ) {
      return setHasError({
        error: true,
        message:
          "Il est nécessaire de remplir un ou plusieurs champs obligatoires.",
      });
    }

    const targetDateTime = new Date(`${time.date} ${time.hour}`);
    const currentDateTime = new Date();
    // const targetTimestamp = targetDateTime.getTime();
    // const currentTimestamp = currentDateTime.getTime();
    console.log(targetDateTime,currentDateTime);
    if(targetDateTime < currentDateTime) {
      return setHasError({
        error: true,
        message:
          "Veuillez choisir une date dans le future.",
      });
    }
    vehiculAvailaility.availability.from.time = `${time.date} ${time.hour}`;
    vehiculAvailaility.availability.passengers = parseInt(passengerLimit);
    vehiculAvailaility.availability.time  = `${time.date} ${time.hour}`
    setHasError({ ...hasError, error: false });
    setIsLoading(true);
    return getLocationsCars(
      {
        departure: vehiculAvailaility.availability.from.location,
        arrival: vehiculAvailaility.availability.to.location,
      }
     // vehiculAvailaility,
      // return getLocationsCars({
      // {availability: {
      //   from: {
      //     location: "63b850942b4a9b14ae10eb86",
      //   },
      //   passengers: 2,
      // },
    
      // availability: {
      //   from: { location: "63bfe3cab182058d86fee7c3" },
      //   passengers: 2,
      // },
    )
      .then((res) => {
        setIsLoading(false);
        setVehicle(res.data.results);
        console.log('first', res.data.results)
        vehiclesRef.current.scrollIntoView({ behavior: "smooth" });
      })
      .catch(() => {
        setIsLoading(false);
        setHasError({
          error: true,
          message: "Aucun véhicule disponible à cette date",
        });
      });
    // return setVehicle([
    //   {
    //     active: true,
    //     name: "Docker",
    //     image: "https://cdn-icons-png.flaticon.com/512/55/55283.png",
    //     type: "SUV",
    //     model: "63ac46b56876dd1fe9d6f14f",
    //     immatricule: "12-B-9992",
    //     passengers: passengerLimit,
    //     availability: [
    //       // {
    //       //   from: {
    //       //     location: "63b850942b4a9b14ae10eb86",
    //       //     time: "2023-01-28T17:30:00.000Z",
    //       //   },
    //       //   to: {
    //       //     location: "63b850942b4a9b14ae10eb8a",
    //       //   },
    //       //   _id: "63bd9a7f573e0a20f4235bc7",
    //       // },
    //       vehiculAvailaility.availability,
    //     ],
    //     amenities: [
    //       {
    //         _id: "63bd9a7f573e0a20f4235bc8",
    //         label: "Wifi",
    //         icon: "https://www.freeiconspng.com/thumbs/wifi-icon/black-wifi-icon-32.png",
    //       },
    //     ],
    //     price: 4000,
    //     id: "63bd9a7f573e0a20f4235bc6",
    //   },
    // ]);
  };
  useEffect(() => {
    console.log('verhicle',vehiculAvailaility);
  },[vehiculAvailaility])

  return (
    <div>
      <div className="h-[700px]">
        <div
          style={{
            background: `url(${require("assets/real-car-view.jpg")}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
          }}
        >
          <div className="relative flex justify-center items-center max-w-4xl mx-auto h-full">
            <div className="flex flex-col p-2 md:flex-row md:p-0">
              <div className="relative hidden md:flex flex-col justify-center itens-center p-10 bg-white/90 md:my-10 rounded-3xl ticket-split">
                <p className="uppercase font-semibold text-primary">TANGIER</p>
                <p className="uppercase font-semibold text-primary">
                  AEROPORT FES SAISS
                </p>
                <p className="uppercase font-semibold text-primary">
                  AEROPORT SALE
                </p>
                <p className="uppercase font-semibold text-primary">
                  GARE AGDAL
                </p>
                <p className="uppercase font-semibold text-primary">FES</p>
                <p className="uppercase font-semibold text-primary">RABAT</p>
                <p className="uppercase font-semibold text-primary">KENITRA</p>

                <img src={barcode} className="w-[150px] mt-5" alt="barcode" />
              </div>
              <div className="relative flex-grow p-10  bg-white/90 md:my-10 rounded-3xl">
                {hasError.error && (
                  <label className="error-alert">{hasError.message}</label>
                )}
                <h1 className="text-xl font-semibold mb-5">
                  Réservez votre trajet dès maintenant
                </h1>
                {/* form */}
                <SearchForm
                  handleTime={(e) =>
                    {
                      console.log(e.target.value);
                      setTime({ ...time, [e.target.name]: e.target.value })
                    }
                  }
                  setVehicle={setVehicle}
                  handleAvailability={setVehiculAvailaility}
                  handlePassengers={setPassengerLimit}
                  vehiculAvailaility={vehiculAvailaility}
                />

                <button
                  className="absolute absolute-bottom-center text-white bg-primary px-8 py-2 border border-gray-400 rounded-full"
                  onClick={submitForm}
                >
                  {isLoading ? <LoadingBar /> : "Chercher"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={vehiclesRef} />
      {vehicle && (
        <div
          className="flex flex-col justify-center bg-gray-100"
          style={{ padding: "2.2rem 2rem 0 2rem" }}
        >
          {vehicle.length !== 0 ? (
            <h1
              style={{
                fontSize: "42px",
                fontWeight: "bold",
                marginLeft: "13vw",
              }}
            >
              Résultats ({vehicle.length})
            </h1>
          ) : (
            ""
          )}
          {vehicle &&
            vehicle.map((v, index) => (
              <VehiculCard
                key={index}
                vehicle={{
                  ...v,
                  vehicleData:{
                    ...v.vehicleData,
                    id:v.vehicleData._id
                  }
                }}
                date={time}
                vehiculAvailaility={vehiculAvailaility}
                passengersLimit={passengerLimit}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default Hero;
