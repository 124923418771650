import React from "react";
import VoyageDirection from "components/VoyageDirection";

// {
//   _id: 6535c0af94ef39f102089a4d,
//   price: 2500,
//   vehicleData: {
//     _id: 64145586b628b4ff1db52ded,
//     active: true,
//     name: 'Autocar',
//     image: 'https://ressources.agence89.com/assets/images/gm2/V2.png',
//     type: 'Autocar',
//     immatricule: '12-B-91212122',
//     passengers: 48,
//     availability: [Array],
//     amenities: [Array],
//     price: 3000,
//     __v: 0
//   },
//   departureLocation: { label: 'Sefrou' },
//   arrivalLocation: { label: 'Taounat' }
// }
const VehiculCard = ({ vehiculAvailaility, vehicle, passengersLimit,date }) => {
console.log('vehicle', vehicle)
  return (
    <div className="relative flex justify-center items-center mx-auto h-full vehicle-item"  data-aos="zoom-in-up"  data-aos-duration="3000">
      <div className="flex flex-col p-2 md:flex-row md:p-0">
        <div className="relative md:flex bg-white flex-col justify-center itens-center max-h-[400px] min-w-[300px] md:my-10 rounded-2xl ticket-split">
          <VoyageDirection 
            vehicle={vehicle}
            direction={vehiculAvailaility}
            passengerLimit={passengersLimit}
            date={date}
            hasSearch
          />
        </div>
        <div className="relative flex-grow p-4 bg-white md:my-10 rounded-3xl max-h-[400px]">
          <img src={vehicle.vehicleData?.image} className="h-full mx-auto" alt="" />
        </div>
      </div>
    </div>
  );
};

export default VehiculCard;
