import React, { useEffect, useState } from "react";
import PassagerForm from "components/PassagerForm";
import VoyageDirection from "components/VoyageDirection";
import cmiLogo from "assets/cmi-logo.png";
import { useLocation } from "react-router";
import { postPassengers } from "services/passengers";
import { dateFormatter } from "utils/dateFormatter";

const Voyage = () => {
  const { state } = useLocation();
  const [passengers, setPassengers] = useState([]);
  const [hasError, setHasError] = useState({ error: false, message: null });
  const [hasSuccess, setHasSuccess] = useState({
    success: false,
    message: null,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const validatePhone = (phone) => {
    return String(phone)
      .toLowerCase()
      .match(/(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}/);
  };
  const renderPassengers = () => {
    const psArray = Array(parseInt(state.passengerLimit)).fill(0);

    return psArray.map((_, index) => {
      return (
        <div className="mt-5">
          <h1 className="text-2xl mb-5">Passager {index + 1}</h1>
          <PassagerForm
            number={index + 1}
            validatePhone={validatePhone}
            passengers={passengers}
            setPassengers={setPassengers}
          />
        </div>
      );
    });
  };
console.log("direction", state.direction)
  return (
    <div className="bg-gray-100 p-6">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col-reverse gap-5 md:flex-row">
          <div className="flex-grow shadow-md rounded-xl p-6">
            {hasSuccess.success && (
              <label className="success-alert">{hasSuccess.message}</label>
            )}
            {hasError.error && (
              <label className="error-alert">{hasError.message}</label>
            )}
            {/* <PassagerForm
              number={0}
              validatePhone={validatePhone}
              passengers={passengers}
              setPassengers={setPassengers}
            /> */}
            {/* Map all passenger forms instead of adding them manually */}
            {renderPassengers()}
            {/* <div className="mt-6">
              <button className="rounded-md p-2 active:bg-slate-200">
                + Ajouter un passager (Max.4)
              </button>
            </div> */}
            <div className="mt-6 text-center">
              <button
                className="text-white px-6 py-2 rounded-full bg-green-600"
                onClick={() => {
                  console.log(passengers);
                  if (passengers.length <= 0 || !passengers.passenger1.email || !passengers.passenger1.first_name || !passengers.passenger1.last_name || !passengers.passenger1.phone_number ) {
                    setHasSuccess({ ...hasSuccess, success: false });
                    setHasError({
                      error: true,
                      message:
                        "Il est nécessaire de remplir un ou plusieurs champs obligatoires.",
                    });
                    return;
                  }else {
                    console.log(state);
                    postPassengers({
                      vehicle: state.vehicle.id,
                      seats: parseInt(state.passengerLimit),
                      date: state.direction.availability.time,
                      passengers: Object.values(passengers),
                      departure: state.direction.availability.from.location,
                      arrival: state.direction.availability.to.location,
                      price: state.newV.price
                    })
                      .then((res) => {
                        setHasError({ ...hasError, error: false });
                        setHasSuccess({
                          success: true,
                          message: "Passenger reservation created successfully",
                        });
                        window.location = res.data.payment_link;
                      })
                      .catch(() => {
                        setHasSuccess({ ...hasSuccess, success: false });
                        setHasError({
                          error: true,
                          message:
                            "Il est nécessaire de remplir un ou plusieurs champs obligatoires.",
                        });
                      });
                  }}
                  }
                 
              >
                Procéder au paiement
              </button>
            </div>
            <p className="text-center mt-10">
              Vous serez rediriger sur la page de notre partenaire{" "}
              <img src={cmiLogo} className="inline w-8" alt="CMI Logo" /> pour
              effectuer le paiement.
            </p>
          </div>
          <div className="md:min-w-[300px]">
            <VoyageDirection
              vehicle={state.newV}
              passengerLimit={state.passengerLimit}
              direction={state.direction}
              date={state.date}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voyage;
